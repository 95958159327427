import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WarehouseOperatorGuideService {

  configService = null;

  constructor(private httpClient: HttpClient) {
    this.configService = new ConfigService();
  }

  getGuide() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.get(this.configService.serverIp.concat('/getAllGuidesInMaster'), { headers });
  }

  searchGuide(guideNumber) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams()
      .set('guide', guideNumber);
    return this.httpClient.get(this.configService.serverIp.concat('/searchGuide'), { headers, params });
  }

  searchGuideService(guideNumber) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams()
      .set('guide', guideNumber);
    return this.httpClient.get(this.configService.serverIp.concat('/searchGuideService'), { headers, params });
  }

  getGuidesForGenerateExcel(index, startDate, endDate) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = { index, startDate, endDate };

    return this.httpClient.get(this.configService.serverIp.concat('/guidesToDeliverCompany'), { headers, params });
  }

  sendGuideForGenerateExcel(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/generateExcelGuidesCompany'), data, { headers });
  }
}
