import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  configService = null;

  constructor(private http: HttpClient) {
    this.configService = new ConfigService();
  }

  getRoutes() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAllCarrierLocal'), { headers });
  }

  createRoute(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/createCarrierLocal'), data, { headers });
  }

  getRoute(id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set("id", id);
    return this.http.get(this.configService.serverIp.concat('/getOneCarrierLocal'), { headers, params });
  }

  updateRoute(value, id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      'id': id,
      'name': value.name,
      'route': value.route,
      'isCarrierOfDestinyCountry': value.isCarrierOfDestinyCountry
    }
    return this.http.post(this.configService.serverIp.concat('/updateCarrierLocal'), data, { headers });
  }

  getUserForRoutes() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getUserForRoutes'), { headers });

  }


}